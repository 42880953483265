<template>
  <div class="loginBySwedishBankIdContainer">
    <b-form @submit="loginBySwedishBankId" class="bg-white text-left loginForm">

      <!-- STEP 1 -->
      <div class="bankid_login_step1" v-if="bankid_login_step1 === true">

        <b-alert show variant="danger" v-if="showErrorAlert">
          <strong>{{ $t(this.translationPath + 'errors.authentication_failed')}}</strong><br/>
          {{ invalidLoginErrorMsg }}
        </b-alert>

        <b-form-group class=" mb-4"
                        v-bind:label="$t('login.label.personal_number')"
                        label-for="personal_number"
                        :invalid-feedback="invalidPersonalNumberFeedback"
                        :description="$t('login.label.personal_number_description')">
            <b-form-input id="personal_number"
                          :placeholder="$t('login.label.personal_number_placeholder')"
                          v-model="personal_number"
                          :state="$v.personal_number.$dirty ? !$v.personal_number.$error : null"
                          @input="$v.personal_number.$touch()"
                          @focus="focusHandler"
            ></b-form-input>
        </b-form-group>

        <b-button type="submit"
                  variant="primary"
                  class="w-100 text-uppercase">
                  {{ $t("login.label.login") }}
        </b-button>
      </div>

      <!-- STEP 2 -->
      <div class="bank-loader text-center pt-3" v-if="bankid_login_step2 === true">
        <div class="spinner-main-loader">
          <div class="spinner-main">
            <div class="spinner-border text-secondary" style="width: 2rem; height: 2rem;" role="status"></div>
          </div>
          <div class="spinner-text pt-4 mb-2">
            <span class="text-secondary">{{ $t('login.label.start_bank_id')}}</span>
          </div>
          <div class="spinner-text py-4 px-2 mb-4">
            <b-button type="button" @click="cancelBankIdLogin" class="w-100 text-uppercase">{{ $t("common.cancel") }}</b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import { required, helpers } from 'vuelidate/lib/validators'
const validPersonalNumberRule = helpers.regex('validPersonalNumber', /^(?:(19)?\d\d|(20)?[0-3]\d)(?:0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-?\d{4}$/)
const validPersonalNumber = getter => function (value) {
  return this.personal_number_ok
}

const CancelToken = axios.CancelToken
let cancel

export default {
  name: 'LoginBySwedishBankId',
  data () {
    return {
      personal_number: null,
      personal_number_ok: true,
      translationPath: 'login.',
      showErrorAlert: false,
      bUserFound: true,
      bankid_login_step1: true,
      bankid_login_step2: false,
      invalidLoginErrorMsg: null
    }
  },
  validations: {
    personal_number: {
      required,
      validPersonalNumberRule,
      personalNumberOk: validPersonalNumber()
    },
    form: ['personal_number']
  },
  methods: {
    ...mapActions('user', ['registerTokensByBankIdLogin']),
    focusHandler () {
      this.$v.$reset()
      this.personal_number_ok = true
      if (this.showErrorAlert) {
        this.showErrorAlert = false
      }
      if (this.invalidLoginErrorMsg) {
        this.invalidLoginErrorMsg = null
      }
    },
    cancelBankIdLogin () {
      this.bankid_login_step1 = true
      this.bankid_login_step2 = false
      cancel()
    },
    async loginBySwedishBankId (event) {
      var self = this
      event.preventDefault()
      this.$v.form.$touch()

      if (this.$v.personal_number.required && this.$v.personal_number.validPersonalNumberRule) {
        this.bankid_login_step1 = false
        this.bankid_login_step2 = true

        /* Check if user with personal_number exits */
        await axios.post(`${process.env.VUE_APP_ROOT_API}/users/personal-number/exists`, { personal_number: this.personal_number })
          .then(function (response) {
            /* if user with personal number found: */
            self.bUserFound = true
          })
          /* if user with personal number does not exist: */
          .catch(function (error) {
            if (error.response.status === 404) {
              self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.no_user_with_personal_id')
              self.bankid_login_step1 = true
              self.bankid_login_step2 = false
              self.showErrorAlert = true
              self.personal_number_ok = false
              self.bUserFound = false
            }
          })

        if (self.bUserFound) {
          await axios.post(`${process.env.VUE_APP_ROOT_API}/bankid/login`, { personal_number: this.personal_number },
            { cancelToken: new CancelToken(function executor (c) { cancel = c }) })
            .then(function (response) {
              /* if user logged in */
              self.bankid_login_step1 = false
              self.bankid_login_step2 = false

              const token = { access_token: response.data.data.tokens.accessToken, token_type: 'Bearer' }
              self.registerTokensByBankIdLogin(token)

              axios.post(`${process.env.VUE_APP_ROOT_API}/me/touch`)
                .catch(error => console.error(error))

              self.$router.push('/dashboard')
            })
            /* if error */
            .catch(function (error) {
              if (axios.isCancel(error)) {
                axios.post(`${process.env.VUE_APP_ROOT_API}/bankid/login/cancel`, { personal_number: self.personal_number })
                self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.bankid_user_cancel')
              } else {
                if (error.response.status === 401) {
                  switch (error.response.data.error.code) {
                    case 'userCancel' :
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.bankid_user_cancel')
                      break
                    case 'cancelled' :
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.bankid_cancelled')
                      break
                    case 'alreadyInProgress' :
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.bank_id_already_in_progress')
                      break
                    case 'certificateErr' :
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.bankid_certificate_error')
                      break
                    case 'expiredTransaction' :
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.bankid_expired_transaction')
                      break
                    case 'invalid_personal_number' :
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.valid_personal_number')
                      break
                    default:
                      self.invalidLoginErrorMsg = self.$i18n.t(self.translationPath + 'errors.unknown_error')
                  }
                }
              }
              self.bankid_login_step1 = true
              self.bankid_login_step2 = false
              self.showErrorAlert = true
              self.personal_number_ok = true
            })
        }
      }
    }
  },
  computed: {
    invalidPersonalNumberFeedback () {
      if (this.$v.personal_number.required === false) { return this.$i18n.t(this.translationPath + 'errors.require_personal_number') }
      if (this.$v.personal_number.validPersonalNumberRule === false) { return this.$i18n.t(this.translationPath + 'errors.valid_personal_number') }
      if (this.$v.personal_number.personalNumberOk === false) { return this.$i18n.t(this.translationPath + 'errors.no_user_with_personal_id') }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./../style.scss";
  .loginBySwedishBankIdContainer{
    .loginForm {
      border: 3px solid #ececec;
      border-radius: 30px;
    }
    #personal_number{
      background: url('../../../assets/img/bankid.gif') no-repeat right top;
    }
  }
</style>
